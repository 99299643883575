.policyFeatures {
  display: flex;
  justify-content: center;
  min-height: 60vh;
  margin-bottom: 100px;
  padding: 20px;
}
.policySection {
  position: relative;
  border-radius: 25px;
  width: 800px;
  transition: 0.3s ease-in-out;
  backdrop-filter: blur(5px);
  box-shadow: 0px 1px 5px black;
}

.policyDetails {
  padding: 20px;
  width: 100%;
}

.policySection:hover {
  transform: scale(1.1);
}
.policyDetails p {
  text-align: justify;
  transition: 0.3s ease-in-out;
}
