.herFeatures {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem;
}
.herFeaturesCol:nth-child(1) {
  border-top-left-radius: 5px;
}
.herFeaturesCol:nth-child(8) {
  border-bottom-right-radius: 5px;
}
.herFeaturesRow {
  backdrop-filter: blur(5px);
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 1px 5px 25px black;
  border-radius: 5px;
}
.herFeaturesCol {
  flex-basis: 50%;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 340px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.herFeaturesCol img {
  width: 100%;
  height: 340px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.herFeaturesCol:hover img {
  transform: scale(1.1);
}
.herFeaturesCol h3 {
  font-size: 25px;
}
.herFeaturesCol p {
  color: #3d3d3d;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  padding: 0 10px;
}
.herFeaturesCol .herFeaturesLink {
  color: #0064d0;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
}
.herFeaturesCol span {
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid #ffffff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
}
.herFeaturesCol .herArrowRight {
  right: unset;
  left: 0;
  border-left: 15px solid #ffffff;
  border-right: 15px solid transparent;
}
@media screen and (max-width: 1020px) {
  .herFeatures {
    padding: 0 0;
    min-height: 70vh;
  }
  .herFeaturesRow {
    width: 95%;
  }
  .herFeaturesCol {
    flex-basis: 50%;
    font-size: 13px;
    height: 240px;
  }
  .herCert {
    padding: 0 50px;
  }
  .herFeaturesCol h3 {
    font-size: 17px;
  }
}
