.servicesFeatures {
  display: flex;
  min-height: 40vh;
  margin-bottom: 100px;
  justify-content: center;
}
.servicesContainer {
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 1300px;
}
.servicesBox .servicesImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.servicesContainer .servicesBox {
  position: relative;
  backdrop-filter: blur(5px);
  box-shadow: 0px 1px 5px black;
  padding: 50px 40px 40px;
  text-align: center;
  overflow: hidden;
  border-radius: 20px;
  background: white;
  transition: 0.3s ease-in-out;
}
.servicesContainer .servicesBox:hover h2,
.servicesBox:hover h3,
.servicesBox:hover p {
  opacity: 0;
}
.servicesBox h4 {
  text-align: center;
  transform: translateY(-300px);
  opacity: 0;
  transition: 0.3s;
  color: rgb(255, 255, 255);
  font-size: 30px;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  text-shadow: 0px 1px 25px black;
}
.servicesBox img {
  z-index: -2;
  position: absolute;
  opacity: 1;
  transform: translateY(0px);
  opacity: 0;
  transition: 0.3s ease-in-out;
  object-fit: cover;
  left: 0;
  top: 0;
}
.servicesBox:hover h4 {
  transform: translateY(130px);
  opacity: 1;
  text-shadow: 0px 1px 15px black;
}
.servicesBox:hover img {
  transform: translateY(0px);
  opacity: 1;
}
.servicesContainer .servicesBox:nth-child(2),
.servicesContainer .servicesBox:nth-child(4) {
  background: #0064d0d8;
  color: white;
}

.servicesContainer .servicesBox h2 {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 120px;
  z-index: 1;
  opacity: 0.4;
  transition: 0.3s ease-in-out;
}
.servicesContainer .servicesBox h3 {
  margin: 0;
  padding: 20px;
  font-size: 21px;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
}
.servicesContainer .servicesBox p {
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 1220px) {
  .servicesContainer {
    width: 800px;
  }
}
