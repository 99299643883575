.slideShow {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: black;
  justify-content: center;
  align-items: center;
  display: flex;
}
.slideShowItem {
  width: inherit;
  height: inherit;
  position: absolute;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: cycleImages 31s infinite;
}
.slideShowItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(rgba(255, 255, 255, 0.938), transparent);
  animation: zoom 31s infinite;
}
.slideShowItem:nth-child(1),
.slideShowItem:nth-child(1) img {
  animation-delay: 0s;
}
.slideShowItem:nth-child(2),
.slideShowItem:nth-child(2) img {
  animation-delay: 10s;
}
.slideShowItem:nth-child(3),
.slideShowItem:nth-child(3) img {
  animation-delay: 20s;
}
.slideShowItemText {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 30%;
  left: 20%;
  color: white;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  width: 60%;
  height: 40%;
  justify-content: center;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
.slideShowItemText h1 {
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 0px, 0px, 20px rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 0.8rem;
}
@keyframes cycleImages {
  25% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}
@keyframes zoom {
  100% {
    transform: scale(1.3);
  }
}
@media screen and (max-width: 970px) {
  .slideShowItemText {
    width: 60%;
    height: 35%;
    text-align: center;
  }
}
@media screen and (max-width: 623px) {
  .slideShowItemText h1 {
    font-size: 2rem;
  }
}
.homeAbout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: "Poppins", serif;
  flex-direction: column;
}
.homeAboutLink {
  padding: 20px;
  animation: bounce 0.8s alternate infinite ease-in;
}
.homeAboutLink .readMore {
  color: #0064d0;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
}
@keyframes bounce {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.homeClients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}
.homeClients h2 {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 70px;
  font-size: 35px;
  padding: 10px;
  border-bottom: 1px solid rgb(212, 212, 212);
}
.rec.rec-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
/* --------------------------- */
.homeFeatures,
.homeFeaturesTitle {
  min-height: 57vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  font-family: "Poppins", serif;
  width: 100%;
}
.homeCard {
  position: relative;
  width: 380px;
  height: 460px;
  margin: 30px;
  overflow: hidden;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(139, 139, 139, 0.342);
  border-left: 1px solid rgba(105, 105, 105, 0.377);
  backdrop-filter: blur(5px);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.homeCardContent {
  padding: 20px;
  text-align: center;
  transform: translateY(100px);
  transform-style: preserve-3d;
  opacity: 0;
  transition: 0.3s;
}
.homeCard:hover .homeCardContent {
  transform: translateY(0px);
  opacity: 1;
}
.homeFeaturesContent {
  display: flex;
  flex-direction: column;
}

.homeFeaturesTitle h2 {
  font-size: 36px;
  text-align: center;
  color: black;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid rgb(212, 212, 212);
}

.homeCardContent i {
  position: absolute;
  top: -120px;
  left: 20px;
  font-size: 8em;
  color: rgba(0, 0, 0, 0.137);
  pointer-events: none;
  z-index: -1;
}
.homeCardContent h3 {
  font-size: 1.8em;
  color: rgb(0, 0, 0);
  z-index: 1;
}
.homeCardImg {
  z-index: -2;
  position: absolute;
  opacity: 1;
  transition: 0.5s;
}
.homeCardImgE {
  z-index: -2;
  position: absolute;
  opacity: 1;
  transition: 0.5s;
  right: 0;
}
.homeCard:hover .homeCardImgE {
  opacity: 0.2;
  filter: blur(2px);
}
.homeCard:hover .homeCardImg {
  opacity: 0.2;
  filter: blur(2px);
}

.homeCardContent p {
  margin-top: 10px;
  font-size: 1.2em;
  color: rgb(94, 94, 94);
  font-weight: 300;
}
.homeCardButton {
  position: relative;
  top: 30px;
  background: #0064d0;
  color: white;
  border-radius: 15px;
  padding: 15px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin: 5px;
}
.homeCardButton:hover {
  transform: scale(1.1);
}

.homeDesc {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Poppins", serif;
  align-items: center;
  display: flex;
  justify-content: center;

  background: rgba(5, 5, 5, 0.911);
}
.descBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}
.homeDesc h2 {
  position: absolute;
  top: 45%;
  color: white;
  font-size: 3.5vw;
  padding: 20px;
  text-shadow: 0px 2px 10px rgb(24, 24, 24);
  position: absolute;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  animation: animate 9s linear infinite;
}
.descHomeWrapper {
  position: absolute;
  top: 20;
  left: 50%;
  right: 10%;
  bottom: 20;
  text-align: center;
  align-items: center;
  backdrop-filter: blur(10px);
  position: absolute;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}
.descHomeWrapper p {
  font-size: 1.35vw;
  padding: 8%;
  border-radius: 15px;
  font-weight: 500;
  font-family: "Roboto", serif;
  color: white;
  text-shadow: 0px 3px 10px black;
}
.homeProducts {
  height: 50px;
  background: rgb(255, 0, 0);
}
.homeQA {
  height: 50px;
  background: blanchedalmond;
}
.homeButtons {
  display: flex;
  justify-content: center;
}
.homeCards .singleHomeCard h2 {
  position: absolute;
  top: -80px;
  right: 30px;
  font-size: 8em;
}
@media screen and (max-width: 550px) {
  .homeCard {
    width: 330px;
  }
  .rec.rec-arrow {
    display: none;
  }
}
.blogBg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
  object-fit: cover;
  opacity: 0.5;
}
@media screen and (max-width: 970px) {
  .blogBg {
    display: none;
  }
}
.flecha {
  position: absolute;
  color: rgba(255, 255, 255, 0.582);
  bottom: 6rem;
  display: flex;
  font-size: 2rem;
  cursor: pointer;
  animation: bounce 0.5s alternate infinite ease-in;
}
.contador {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rec.rec-carousel {
  padding-left: 30px;
  padding-right: 30px;
}
.rec.rec-arrow:focus:enabled,
.rec.rec-arrow:hover:enabled {
  background-color: #0064d0;
}
.rec.rec-dot.rec-dot_active {
  box-shadow: 0 0 1px 3px#0064d0;
  background-color: #0065d071;
}
.rec.rec-dot:hover {
  box-shadow: 0 0 1px 3px#0064d0;
}

.homeClientsImg {
  width: 250px;
}
@media screen and (max-width: 550px) {
  .slideShowItemText {
    width: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(1px);
  }
  .slideShowItemText h1 {
    text-align: center;
    font-size: 55px;
  }
}
