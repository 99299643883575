.aboutPage {
  font-family: "Poppins", serif;
}

.aboutCertBox {
  min-height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutCertBox h2 {
  margin-bottom: 40px;
  padding: 10px;
  border-bottom: 1px solid rgb(212, 212, 212);
}
.aboutCertBox .aboutCert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
}
.aboutCertBox .aboutCert img {
  width: 15rem;
  padding: 1rem;
}
@media screen and (max-width: 970px) {
  .aboutTextTop {
    width: 80%;
    height: 30%;
    text-align: center;
  }
}
@media screen and (max-width: 623px) {
  .aboutTextTop h1 {
    font-size: 2rem;
  }
}

/* ------------------------- */
.aboutFeatures {
  min-height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutFeaturesCol:nth-child(1) {
  border-top-left-radius: 5px;
}
.aboutFeaturesCol:nth-child(8) {
  border-bottom-right-radius: 5px;
}
.aboutFeaturesRow {
  backdrop-filter: blur(5px);
  width: 80%;
  margin: 4% auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 1px 5px 25px black;
  border-radius: 5px;
}
.aboutFeaturesCol {
  flex-basis: 25%;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 240px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.aboutFeaturesCol img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.aboutFeaturesCol:hover img {
  transform: scale(1.1);
}
.aboutFeaturesCol h3 {
  font-size: 20px;
}
.aboutFeaturesCol p {
  color: #3d3d3d;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
}
.aboutFeaturesCol .aboutFeaturesLink {
  color: #0064d0;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
}
.aboutFeaturesCol span {
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid #ffffff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
}
.aboutFeaturesCol .aboutArrowRight {
  right: unset;
  left: 0;
  border-left: 15px solid #ffffff;
  border-right: 15px solid transparent;
}
@media screen and (max-width: 970px) {
  .aboutFeaturesRow {
    width: 95%;
  }
  .aboutFeaturesCol {
    flex-basis: 50%;
    font-size: 14px;
  }
  .aboutCert {
    padding: 0 50px;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(1) {
    order: 1;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(2) {
    order: 2;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(3) {
    order: 5;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(4) {
    order: 6;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(5) {
    order: 3;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(6) {
    order: 4;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(7) {
    order: 7;
  }
  .aboutFeaturesRow .aboutFeaturesCol:nth-child(8) {
    order: 8;
  }
}
