.topAll {
  width: 100%;
  height: 100vh;
  padding: 0 8%;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}
.topAll img {
  position: absolute;
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.textTop {
  color: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  width: calc(100%-100px);
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  width: 60%;
  height: 40%;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}
.textTop h1 {
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 0px, 0px, 20px rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 0.8rem;
}
.textTop p {
  margin-bottom: 0.8rem;
  font-size: 1rem;
  text-shadow: 0px, 0px, 20px rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 970px) {
  .textTop {
    width: 80%;
    height: 35%;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .textTop {
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
  }
  .textTop h1{
    text-align: center;
  }
  .topAll {
    padding: 0 2%;
  }
}
