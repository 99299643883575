.valuesFeatures {
  display: flex;
  justify-content: center;
  min-height: 60vh;
  margin-bottom: 100px;
  padding: 20px;
}
.valuesSection {
  position: relative;
  border-radius: 25px;
  width: 800px;
}
.valuesSection ul {
  margin: 0;
}
.valuesSection ul li {
  list-style: none;
  min-height: 200px;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 1px 5px black;
  margin-bottom: 20px;
  border-radius: 15px;
  cursor: pointer;
  backdrop-filter: blur(5px);
}
.valuesSection ul li:hover {
  transform: scale(1.1);
}
.valuesSection ul li .valuesIcon {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  width: 20%;
  float: left;
  text-align: center;
  transition: 0.5s ease-in-out;
  font-size: 45px;
}
.valuesDetails {
  padding: 20px;
  width: 80%;
}
.valuesSection ul li:hover .valuesIcon {
  color: #0064d0;
  transform: scale(1.3);
}

.valuesDetails h3 {
  font-size: 25px;
  transition: 0.3s ease-in-out;
}
.valuesSection ul li:hover h3 {
  color: #0064d0;
}
.valuesDetails p {
  text-align: justify;
}
@media screen and (max-width: 550px) {
  .valuesSection ul li .valuesIcon {
    display: none;
  }
  .valuesDetails {
    width: 100%;
    text-align: center;
  }
}
