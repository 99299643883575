.headersAll {
  margin-top: 6rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
}
.headersAll .headersTitle h2 {
  font-size: 36px;
  color: black;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid rgba(197, 197, 197, 0.856);
  text-align: center;
}
.headersContent p {
  text-align: center;
  padding: 0 27vw;
}
@media screen and (max-width: 770px) {
  .headersContent p {
    padding: 0 10vw;
  }
  .headersAll .headersTitle h2{
    font-size: 25px;
  }
}
